<script>

import {
  merge,
  debounce,
  cloneDeep,
} from 'lodash'

import PlanitInput from '@components/form/input'
import LicenseLabel from '@components/license-label'
import LicenseFeatureCardContent from '@components/license-feature-card-content'
import PlanitAlert from '@components/planit-alert'
import PlanitBasketContent from '@components/planit-basket-content'
import PlanitProduct from '@components/planit-product'
import UserVerifyAlert from '@components/user-verify-alert'
import Die3Description from '@components/die3-description'
import {
  BASIC_PRICE_MONTHLY,
  PRO_PRICE_MONTHLY,
  LicenseType,
  PaymentMethod,
  PaymentState,
  OrderType,
} from '@enums'

import store from '@state/store'
import Address from '@state/models/address'
import Basket from '@state/models/basket'
import BrandSetting from '@state/models/brand-setting'
import Promotion from '@state/models/promotion'


export default {
  name: 'Checkout',
  components: {
    PlanitInput,
    LicenseLabel,
    LicenseFeatureCardContent,
    PlanitAlert,
    PlanitBasketContent,
    PlanitProduct,
    UserVerifyAlert,
    Die3Description,
  },
  props: {
    company: { type: Object, required: false, default: null },
    noBasketMode: { type: Boolean, required: false, default: false },
    initialOrders: { type: Array, required: false, default: null },
    checkoutTitle: { type: String, required: false, default: null },
    promotionProp: { type: Object, required: false, default: () => ({}) },
  },
  emits: ['close'],
  data: function () {
    return {
      BASIC_PRICE_MONTHLY,
      PRO_PRICE_MONTHLY,
      LicenseType,
      PaymentMethod,

      checkoutStep: 'personalInfo',
      checkoutSteps: [
        'personalInfo',
        'address',
        'paymentMethod',
        'paymentSuccess',
      ],

      selectedPaymentMethod: PaymentMethod.INVOICE,
      explicitConfirm: false,

      payLoading: false,

      submitError: null,
      validateErrors: {},

      localBasket: {
        data: {
          email: null,
          name: null,
          vat_id: null,
          address: {
            street_line_1: null,
            street_line_2: null,
            postal_code: null,
            city: null,
            country: null,
          },
          promotionCode: null,
        },
        orders: [],
        user_email: null,
        user_password: null,
      },

      noBasketReferenceNumber: null,
      userSameEmailAsBasket: true,

      verifyTimer: null,
      showGenericError: false,

      vatIdLater: false,
      promotion: {},
      debouncedIsPromotionValid: null,
      promotionError: null,
    }
  },
  computed: {
    basket() {
      return this.noBasketMode ? this.localBasket : store.getters['checkout/unpaidBasket']
    },
    user() {
      return store.getters['auth/user']
    },
    currentUser() {
      return store.getters['auth/currentUser']
    },
    orders() {
      return this.basket.orders
    },
    licenseType() {
      return this.orders[0]?.data?.license_type
    },
    checkoutStepComputed: {
      get() {
        return this.checkoutSteps.indexOf(this.checkoutStep) + 1
      },
      set(v) {
        this.checkoutStep = this.checkoutSteps[v - 1]
      }
    },
    paymentMethodItems() {
      return Object.values(PaymentMethod).map((paymentMethod) => {
        const icon =
          paymentMethod === PaymentMethod.INVOICE ? 'fal fa-file-invoice' :
          paymentMethod === PaymentMethod.PAYPAL ? 'fab fa-paypal' :
          null
        const additionalPrice = 0

        return {
          value: paymentMethod,
          icon,
          additionalPrice,
        }
      })
    },
    companyAddress() {
      return this.company && this.company.address
    },
    isDie3Basket() {
      return (
        this.basket &&
        this.basket.orders.length > 0 &&
        this.basket.orders.some(o => o.order_type === OrderType.DIE3)
      )
    },
    die3Mode() {
      return store.getters['global/die3Mode']
    },
    referenceNumber() {
      return this.noBasketReferenceNumber ||
        (this.basket.getReferenceNumber && this.basket.getReferenceNumber())
    },
    tosTo() {
      return store.getters['global/tosTo']
    },
    brandSetting() {
      return BrandSetting.query().first()
    },
  },
  async created() {
    this.debouncedIsPromotionValid = debounce(this.isPromotionValid, 500)
    const preFill = { data: {} }
    if (this.noBasketMode) {
      store.dispatch('syncQueue/pause')
    }
    if (this.noBasketMode && this.die3Mode) {
      const initialLicenseOrder = this.initialOrders.find(o => o.order_type === OrderType.DIE3)
      this.localBasket.orders.push({
        license_type: LicenseType.BASIC,
        order_type: OrderType.DIE3,
        price_custom: initialLicenseOrder?.price_custom ? initialLicenseOrder.price_custom : 0,
        quantity: 12,
      })
    }

    if (this.noBasketMode) {
      this.basket.total_price = this.basket.orders.reduce(
        (acc, order) => acc + order.price_custom,
        0
      )
      if (this.die3Mode) {
        this.basket.total_price *= 12
      }
    }

    // pre-fill email
    if (!this.basket.data?.email && this.user?.verified && this.user?.email) {
      preFill.data.email = this.user.email
    }

    // only pre-fill company name if available
    if (!this.basket.data?.name && !!this.company) {
      preFill.data.name = this.company.name
    }

    if (this.promotionProp && Object.keys(this.promotionProp).length) {
      this.promotion = this.promotionProp
      this.basket.data.promotionCode = this.promotionProp.code
    }

    if (this.basket.data?.promotionCode) {
      await this.isPromotionValid(this.basket.data.promotionCode)
    }

    if (!!this.companyAddress) {
      preFill.address = {}

      for (let addressField of [
        'street_line_1',
        'street_line_2',
        'postal_code',
        'city',
        'state',
        'country',
      ]) {
        if (
          !this.basket.billing_address[addressField] &&
          this.companyAddress[addressField]
        ) {
          preFill.address[addressField] = this.companyAddress[addressField]
        }
      }
    }

    if (Object.keys(preFill).length > 1 || Object.keys(preFill.data).length > 0) {
      const newData = merge(
        {},
        { data: cloneDeep(this.basket.data), address: cloneDeep(this.basket.billing_address) },
        preFill,
      )

      await this.basket.updateAttr(newData)

      if (preFill.data) {
        await this.basket.sync(null, { data: newData.data })
        this.$store.dispatch('checkout/refreshUnpaidBasket')
      }

      if (preFill.address) {
        await this.basket.billing_address.sync(null, preFill.address)
        this.basket.data.address = preFill.address
      }
    }
  },
  methods: {
    async submitBasket() {
      this.payLoading = true
      if(!this.noBasketMode){
        await this.$store.dispatch('checkout/refreshUnpaidBasket')
      }
      const handleBasketError = (error) => {
        if (error?.response?.data?.message) {
          this.submitError = error.response.data.message

        } else if (error?.response?.data && Object.keys(error.response.data).length) {
          this.validateErrors = error.response.data

          // Also show email error on second field is its used for both
          if (this.userSameEmailAsBasket && this.validateErrors.user_email) {
            this.validateErrors.email = this.validateErrors.user_email
          }

          if (this.vatIdLater && this.validateErrors.vat_id) {
            delete this.validateErrors.vat_id
          }

        } else {
          this.submitError = this.$t('generic.default_error')

        }

        this.payLoading = false

      }

      let result

      if (this.noBasketMode) {
        const basketCopy = { ...this.basket }
        delete basketCopy.total_price

        const basketPostData = {
          ...basketCopy,
          payment_method: this.selectedPaymentMethod,
          user_email: this.userSameEmailAsBasket ? this.basket.data.email : this.basket.user_email,
          user_password: this.basket.user_password || '',
        }

        if (this.vatIdLater) {
          basketPostData.data.vat_id = 'n/a'
        }
        result = await Basket.$new(basketPostData, { params: { create_company: !!this.die3Mode }})
        .catch(handleBasketError)

        if (
          result?.response?.status == 200 &&
          result?.response?.data &&
          Object.keys(result.response.data).filter(k => k !== '$resolved').length === 0
        ) {
          // Empty 200 means email address already in use
          this.showGenericError = true
        }

        if (result?.response?.data?.uuid) {
          this.noBasketReferenceNumber = result.response.data.uuid.split('-')[4].slice(6)

          await store.dispatch('auth/logIn', {
            email: basketPostData.user_email,
            password: basketPostData.user_password,
            skipSaml: true,
          })

          store.dispatch('syncQueue/unpause')

          this.verifyTimer = setInterval(async () => {
            await store.dispatch('auth/validate', { refresh: true })

            if (this.currentUser?.verified) {
              clearInterval(this.verifyTimer)
              setTimeout(() => {
                location.href = '/profile'
              }, 2000)
            }
          }, 1000)
        }

      } else {
        await store.dispatch('syncQueue/handleQueueNow')

        const basketData = { payment_method: this.selectedPaymentMethod }
        if (this.vatIdLater) {
          basketData.data = { ...this.basket.data, vat_id: 'n/a' }
        }
        await this.basket.sync(null, basketData)

        result = await this.basket.sync(null, {
          payment_state: PaymentState.SUBMITTED
        }, {
          params: { create_company: false },
        }).catch(handleBasketError)
      }

      if (result) {
        this.validateErrors = {}
        this.payLoading = false
        this.checkoutStep = 'paymentSuccess'
      }

    },
    closeCheckout() {
      this.$emit('close')
    },
    async isPromotionValid(code) {
      if (!code) {
        this.resetPromoState()
        return
      }
        const license = this.getLicenseType()
        const response = await Promotion.getPromotionByCodeAndType(code, license)
        .catch(this.setInvalidPromoState)

        if (response && response.response && response.response.data) {
          this.setValidPromoState(response.response.data)
        }
        this.$store.dispatch('checkout/setUnpaidBasket')
    },
    getLicenseType() {
      return this.die3Mode ? OrderType.DIE3 : this.basket.orders[0]?.data?.license_type
    },
    setValidPromoState(data) {
      this.promotion = data
      this.promotionError = null
    },
    setInvalidPromoState(error) {
      this.promotion = {}
      if (error.response && error.response.data && error.response.data.message) {
        this.promotionError = error.response.data.message
      } else {
        this.promotionError = this.$t('promotion/unknown_error')
      }
    },
    resetPromoState() {
      this.promotion = {}
      this.promotionError = null
    },
  },
}
</script>

<template>
  <div>
    <v-row no-gutters>
      <v-col
        cols="12"
        md="8"
        order="2"
        order-sm="1"
      >
        <div v-if="checkoutStep !== 'paymentSuccess'">
          <div
            class="shephard-planit-tour"
          >
            <div
              class="shepherd-header justify-space-between"
              style="border-top-left-radius: 4px; border-top-right-radius: 4px; padding-left: 40px !important;"
            >
              <h3 class="shepherd-title">{{ checkoutTitle || $t('checkout.checkout_title') }}</h3>

              <PlanitButton
                variant="text"
                class="icon-btn"
                @click="$emit('close')"
              >
                <PlanitIcon icon="fal fa-times"></PlanitIcon>

              </PlanitButton>
            </div>
          </div>

          <div
            class="pb-6 px-10"
          >
            <div
              class="mb-2"
            >
              <h3>{{ $t('checkout.step1_general_info') }}</h3>
            </div>
            <div class="mb-8">
              <div>
                <v-row class="mb-4">
                  <v-col cols="12" md="6">
                    <PlanitInput
                      :model="basket"
                      :label="$t('checkout.business_name')"
                      attr="data.name"
                      :error-message="validateErrors.name && validateErrors.name[0]"
                    ></PlanitInput>
                  </v-col>
                  <v-col cols="12" md="6">
                    <PlanitInput
                      :model="basket"
                      :label="$t('checkout.vat_id')"
                      attr="data.vat_id"
                      :error-message="validateErrors.vat_id && validateErrors.vat_id[0]"
                      :disabled="vatIdLater"
                      class="mb-2"
                    ></PlanitInput>
                    <PlanitSwitch
                      v-model="vatIdLater"
                      :label="$t('checkout.vat_id_later')"
                      hide-details
                    ></PlanitSwitch>
                  </v-col>
                </v-row>

                <PlanitInput
                  :model="basket"
                  attr="data.email"
                  :label="$t('generic.email')"
                  :error-message="validateErrors.email && (validateErrors.email[0].length > 1 ? validateErrors.email[0] : validateErrors.email)"
                  highlight
                  class="mb-4"
                ></PlanitInput>

              </div>
            </div>

            <div
              class="mb-2"
            >
              <h3>{{ $t('checkout.step2_billing_address') }}</h3>
            </div>
            <div class="mb-4">
              <div>
                <v-row>
                  <v-col cols="12" sm="6">
                    <PlanitInput
                      :model="basket"
                      attr="data.address.street_line_1"
                      :label="$t('generic.street_line', { n: 1 })"
                      :error-message="validateErrors.street_line_1 && validateErrors.street_line_1[0]"
                      highlight
                    ></PlanitInput>

                  </v-col>
                  <v-col cols="12" sm="6">

                    <PlanitInput
                      :model="basket"
                      attr="data.address.street_line_2"
                      :label="$t('generic.address_supplement') + ' (' + $t('generic.optional') + ')'"
                      :error-message="validateErrors.street_line_2 && validateErrors.street_line_2[0]"
                      highlight
                    ></PlanitInput>

                  </v-col>
                  <v-col cols="12" sm="4">
                    <PlanitInput
                      :model="basket"
                      attr="data.address.postal_code"
                      :label="$t('generic.postal_code')"
                      :error-message="validateErrors.postal_code && validateErrors.postal_code[0]"
                      highlight
                      class="mb-4"
                    ></PlanitInput>

                  </v-col>
                  <v-col cols="12" sm="4">

                    <PlanitInput
                      :model="basket"
                      attr="data.address.city"
                      :label="$t('generic.city')"
                      :error-message="validateErrors.city && validateErrors.city[0]"
                      highlight
                    ></PlanitInput>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <!--<PlanitInput
                      :model="basket"
                      attr="data.address.state"
                      :label="$t('generic.state')"
                      highlight
                      class="mb-4"
                    ></PlanitInput>-->

                    <PlanitInput
                      :model="basket"
                      attr="data.address.country"
                      :label="$t('generic.country')"
                      :error-message="validateErrors.country && validateErrors.country[0]"
                      highlight
                    ></PlanitInput>
                  </v-col>
                </v-row>

              </div>
            </div>

            <div
              class="mb-2"
            >
              <h3>{{ $t('checkout.step3_payment_method') }}</h3>
            </div>
            <div>
              <v-row class="mb-6">
                <v-col
                  v-for="paymentMethodItem in paymentMethodItems"
                  :key="paymentMethodItem.value"
                  cols="12"
                  md="6"
                >
                  <div
                    class="d-flex align-center cursor-pointer sheet-border sheet-border-active pa-4"
                    @click="selectedPaymentMethod = paymentMethodItem.value"
                  >
                    <PlanitIcon
                      style="font-size: 150%"
                      color="primary"
                      class="mr-2"
                      :icon="paymentMethodItem.icon"
                    >
                    </PlanitIcon>

                    <h3 class="mr-2">
                      {{ $t('enums.payment_method.' + paymentMethodItem.value) }}
                    </h3>

                    <PlanitIcon
                      class="ml-auto align-self-start"
                      :color="
                        selectedPaymentMethod === paymentMethodItem.value ?
                          'primary' : 'default'
                      "
                      :icon="
                        selectedPaymentMethod === paymentMethodItem.value ?
                          'fas fa-toggle-on': 'fal fa-toggle-off'
                      "
                    ></PlanitIcon>
                  </div>
                </v-col>
                <v-col>
                  <PlanitInput
                    :model="basket"
                    attr="data.promotionCode"
                    :label="$t('checkout.promotion')"
                    :error-message="promotionError "
                    immediateSave
                    highlight
                    @input="debouncedIsPromotionValid($event.target.value)"
                  ></PlanitInput>
                </v-col>
              </v-row>

            </div>

            <div v-if="noBasketMode">
              <div class="mb-2">
                <h3>{{ $t('checkout.step2_5_user_email_password') }}</h3>
              </div>
              <div class="mb-4">
                <PlanitSwitch
                  v-model="userSameEmailAsBasket"
                  :label="$t('checkout.user_same_email_as_basket')"
                  hide-details
                  class="mb-4"
                ></PlanitSwitch>

                <PlanitInput
                  v-if="!userSameEmailAsBasket"
                  :model="basket"
                  attr="user_email"
                  :label="$t('generic.email')"
                  :error-message="validateErrors.user_email"
                  highlight
                  class="mb-4"
                ></PlanitInput>

                <PlanitInput
                  :model="basket"
                  input-type="password"
                  attr="user_password"
                  :label="$t('login.password')"
                  :error-message="validateErrors.user_password && validateErrors.user_password[0]"
                  highlight
                  class="mb-4"
                ></PlanitInput>

                <div>
                  <i18n-t keypath="invite.opt_in_tos">
                    <template #termsOfService>
                      <router-link :to="tosTo" target="_blank" class="font-weight-medium">
                        {{ $t('components.footer.terms_of_use') }}
                      </router-link>
                    </template>
                    <template #productName>
                      <PlanitProduct v-if="!brandSetting" text="PRIMA"></PlanitProduct>
                      <span v-else>{{ brandSetting.name }}</span>
                    </template>
                  </i18n-t>
                </div>

              </div>
            </div>

            <div>{{ $t('die3.checkout_company_only_confirm') }}</div>

            <div>
              <form @submit.prevent="submitBasket">
                <div v-if="submitError" class="mb-6">
                  <PlanitAlert
                    color="error"
                    icon=""
                  >
                    <div>
                      {{ submitError }}
                    </div>
                  </PlanitAlert>
                </div>

                <div class="d-flex justify-end">
                  <PlanitButton
                    color="primary"
                    size="large"
                    :loading="payLoading"
                    type="submit"
                  >
                    <i18n-t keypath="checkout.pay">
                      <template #amount>{{ $filters.formatCurrency(basket.total_price) }}</template>
                    </i18n-t>
                  </PlanitButton>
                </div>
              </form>
            </div>

          </div>
        </div>
        <div v-if="checkoutStep === 'paymentSuccess'">
          <div class="pa-10">
            <div v-if="!showGenericError" class="d-flex flex-column align-center">
              <PlanitIcon class="mx-auto mb-2" size="x-large" color="success" icon="fas fa-check-circle"></PlanitIcon>

              <h2 class="text-success mb-4 text-center">{{ $t('checkout.order_received') }}</h2>
              <div v-if="referenceNumber" class="text-grey">Reference Number: {{ referenceNumber }}</div>
            </div>
            <PlanitAlert v-else color="error">
              {{ $t('components.profile_picture.error_generic') }}
            </PlanitAlert>
          </div>
          <v-divider></v-divider>

          <div v-if="!die3Mode || !noBasketMode" class="pa-10 d-flex align-center justify-end">
            <PlanitButton
              color="primary"
              primary
              @click="closeCheckout"
            >
              <span v-if="die3Mode || !!company">{{ $t('generic.close') }}</span>
              <span v-else>{{ $t('generic.add_company') }}</span>
            </PlanitButton>

            <!-- <dl class="ml-auto">
              <dd>{{ $t('checkout.total_amount') }}</dd>
              <dt>{{ basket.total_price | currency }}</dt>
            </dl> -->
          </div>

          <div v-if="die3Mode && noBasketMode" class="pa-4">
            <div class="d-flex justify-space-around mx-auto" style="width: 100%; max-width: 42rem;">
              <UserVerifyAlert
                :email="basket.user_email || basket.data.email"
                hide-login-differently
              ></UserVerifyAlert>
            </div>
          </div>
        </div>
      </v-col>

      <v-col
        cols="12"
        md="4"
        order="1"
        order-sm="2"
        class="align-self-stretch"
      >
        <div
          :class="
            !isDie3Basket ?
            [
              'login-card-background',
              licenseType === 'pro' ? 'primary' : 'primary'
            ] : 'die3-background-wo-text'
          "
          class="fill-height pa-4 d-flex flex-column justify-space-around"
          :style="isDie3Basket ? { 'min-height': '20rem' } : {}"
        >
          <!-- <div v-if="licenseType" class="text-white px-10">
            <LicenseFeatureCardContent
              :license-type="licenseType"
              no-background-color
            ></LicenseFeatureCardContent>
          </div> -->

          <div class="bg-white elevation-3 border-radius pa-4">
            <div v-if="die3Mode" class="mb-6">
              <div class="mb-4 px-4">
                <Die3Description></Die3Description>
              </div>
              <v-divider></v-divider>
            </div>

            <h2>{{ $t('checkout.order_overview') }}</h2>
            <PlanitBasketContent
              :basket="basket"
              :promotion="promotion"
              hide-buttons
            ></PlanitBasketContent>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style>
  .v-stepper__step__step .PlanitIcon.PlanitIcon {
    padding-top: 1px;
    padding-left: 1px;
  }

  .v-stepper__step__step .PlanitIcon.PlanitIcon, .v-stepper__step__step {
    font-size: 1rem;
  }
</style>
@/src/state/models/promotion